import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import '../../Cards/Poker/Poker.scss'
import { Container } from 'react-bootstrap'
import GamesIntro from '../../GamesIntro/GamesIntro'
import Contests from '../../Contests'
import GameTestimonials from '../../Cards/Poker/GameTestimonials'
import OtherGames from './OtherGames'
import DownloadBottomSheet from '../../../Download/DownloadBottomSheet'
import DesktopTitle from './DesktopTitle'
import HopPopInfo from '../../CategoryAndGamesInfo/HopPopInfo'
import HomeFAQs from '../../../Index/HomeFAQs/HomeFAQs'
import { Faq } from '../../../FAQs/faqs-list-interface'
import useTestimonials from '../../../Testimonials/useTestimonials'
import { HOPPOP } from '../../../download-links'
import PaymentPartners from '../../../Index/PaymentPartners/PaymentPartners'
import Features from '../../../Index/Features/Features'
import SafeAndCertified from '../../../Index/SafeAndCertified/SafeAndCertified'

const HopPop: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "game-banners/hop-pop.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const [hoppopFaqsSt, setHoppopFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../../FAQs/faqs-list').then(({ hoppopFaqs }) =>
      setHoppopFaqsSt(hoppopFaqs)
    )
  }, [])

  const [showDownload, setShowDownload] = useState(false)

  const hideBottomSheet = (): void => {
    setShowDownload(false)
  }

  const { casualTestimonials } = useTestimonials()

  return (
    <div id="poker">
      <GamesIntro
        thumbnail={data.intro.childImageSharp.gatsbyImageData}
        addImageMask
        gameName="HopHop"
        apkLink={HOPPOP}
      />
      <DesktopTitle />
      <Contests />
      <PaymentPartners />
      <Features />
      <SafeAndCertified hideGame />
      <GameTestimonials testimonials={casualTestimonials.slice(0, 3)} />
      <div className="faqs-wrap">
        <HomeFAQs faqs={hoppopFaqsSt} />
      </div>
      <OtherGames />
      <Container className="hophop-container">
        <HopPopInfo />
      </Container>
      <DownloadBottomSheet
        show={showDownload}
        hideBottomSheet={hideBottomSheet}
        downloadLink={HOPPOP}
      />
    </div>
  )
}

export default HopPop
