import React from 'react'
import './CategoryInfo.scss'

const HopPopInfo: React.FC = () => {
  return (
    <div className="category-info">
      <h2 className="page-title">Learn more about HopPop</h2>
      <p>
        Remember Chinese Checkers? The star-shaped board game where you
        skillfully jump colourful marbles to capture the opposite side? Trying
        to find the best route on the board, and showing off long dramatic
        moves?
      </p>
      <p>
        The family favourite game of Chinese Checkers is played on a 6-pointed
        star shaped board, between 2, 3, 4 or 6 players. The rules of the game
        are fairly simple — hop your marbles across the board before anyone else
        — but winning totally depends on strategy, tactics and quick thinking.
        Trying to predict others’ moves, spoiling their tactics while making our
        own moves — Chinese Checkers was a fun experience for everyone sitting
        together.
      </p>
      <p>
        What if we told you that you could play Chinese Checkers online with
        real people, in real time? And what if you could win real money while
        doing so?
      </p>
      <p>
        Download Mega on your android phone. Play Hop Pop, a multiplayer game of
        Chinese Checkers online, with the added thrills of real money. Find the
        best route, hop your marbles to the opposite side and pop away others’
        marbles on the way. Beat real players in short fun rounds, and win
        exciting real money rewards!
      </p>

      <h3>Rules</h3>
      <ul>
        <li>
          <p>
            Hop Pop is played between 2-4 players. Each player starts with 10
            points. The goal is to ‘race to zero’ — reduce points the fastest
            and reach zero to win.
          </p>
        </li>
        <li>
          <p>
            Move your marble to the next empty hole or jump over other marbles
            to make bigger leaps, before the timer runs out.
          </p>
        </li>
        <li>
          <p>
            You can move only one marble in a turn. Each possible move from
            current position is highlighted in the game.
          </p>
        </li>
        <li>
          <p>
            Jumping over opponents’ marble eliminates that marble and reduces
            the score by 2 points. Any player who loses all the marbles is out
            of the game.
          </p>
        </li>
        <li>
          <p>
            Every marble that reaches the opposite home reduces the score by 5
            points.
          </p>
        </li>
        <li>
          <p>
            The player who reaches ‘0’ first wins. The total points of the
            remaining players are added to decide the total cash prize.
          </p>
        </li>
      </ul>

      <h3>Tips and Strategies</h3>
      <p>
        A few simple tips before you jump into playing Chinese Checkers games
        online. A basic strategy is to create or find the longest hopping path
        leading to the opposite end, since multiple-jumps are faster to advance
        marbles than step-by-step moves.
      </p>
      <p>
        Skilfully empty your own starting house by lining your marbles and
        creating gaps to hop your marbles forward in fewer moves. Predicting and
        blocking opposing players’ moves can help you block their win.
      </p>
      <p>
        Remember, unlike regular Chinese Checkers, there is more than one way to
        score in Hop Pop. — by hopping your marbles to the opposite home, and
        also by popping off opponents’ marbles. So keep a look out for opponent
        marbles, and find the best way to score the most.
      </p>

      <h3>Real people, Real fun</h3>
      <p>
        HopPop is played in short, fun rounds between 2-4 people. Mega allows
        only 100% verified profiles to compete on the platform. So you can play
        the game of Chinese checkers in the safest and most reliable way online.
        No bots, no tricks. Real people, with real money wins.
      </p>

      <h3>Quick, exciting games for everyone</h3>
      <p>
        HopPop is designed for the quick rounds of nail biting gameplay. It is
        rich in strategy and tactics, and rewards players who practice, and
        possess good observation skills and decision making. The buy-in amount
        of Hop Pop tables start from as low as ₹1 — so whatever be the size of
        your pockets, or your level of expertise, there is a perfect way for you
        to play Chinese checkers online.
      </p>

      <h3>User-friendly controls, bright graphics</h3>
      <p>
        The shiny colorful marbles will take you back to your childhood days,
        and the simple, clean design helps you maximise the fun. Each possible
        move of the selected marble is highlighted, so you can focus on making
        the right choice . Tap on the right hole, and find the best possible
        route to hop over to the other side, without any hassles.
      </p>

      <h3>Instant Wins &amp; Withdrawals</h3>
      <p>
        You can play any number of rounds on a table. The total amount won is
        credited to your winnings wallet as soon as you leave the table. The
        winnings can be transferred to Paytm, through UPI or directly to your
        bank account, in less than a minute.
      </p>
    </div>
  )
}

export default HopPopInfo
