import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import HopPopPage from '../../components/Games/Casual/HopPop/HopPop'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { CASUAL, GAMES, HOP_POP } from '../../components/internal-links'
import { HOPPOP } from '../../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Casual',
    url: CASUAL,
  },
  {
    title: 'Hop-Pop (Chinese Checkers)',
    url: HOP_POP,
  },
]

const HopPop: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={HOPPOP}
      desktopBreadcrumbColor="black"
    >
      <SEO
        title="Play Hop Pop (Chinese Checkers): Learn How to Play | Play & Win Cash"
        description="Enjoy your childhood favourite Chinese Checkers game online. Download Mega & Play HopPop. Hop your marbles skilfully, defeat real players & win real cash."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <HopPopPage />
    </Layout>
  )
}

export default HopPop
