import React from 'react'
import '../../Cards/Poker/OtherGames.scss'
import { graphql, useStaticQuery } from 'gatsby'
import DesktopSmall from '../../GameCard/DesktopSmall'
import { POKER } from '../../../internal-links'

const OtherGames: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      Poker: file(
        relativePath: { eq: "homepage-cards/Poker-Homepage cards.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <div id="other-games">
      <div className="text">You may also like</div>
      <div className="desktop-grid">
        <div>
          <DesktopSmall
            category="cards"
            showCategory
            name="Poker"
            showName
            showDescription
            description="Win exciting real money prizes with Mega Poker."
            detailsBackground="#FF3D3C"
            gameImage={data.Poker.childImageSharp.gatsbyImageData}
            gameUrl={POKER}
          />
        </div>
      </div>
    </div>
  )
}

export default OtherGames
